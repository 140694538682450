import React from "react"
// import { Link } from "gatsby"

import { OutboundLink } from "gatsby-plugin-gtag"

import styled from "@emotion/styled"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import Image from "../components/image"
import Dumpling from "../components/dumpling"
import Sashimi from "../components/sashimi"
import SEO from "../components/seo"

const ImageView = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
`

const ImageWrapper = styled.div`
  width: calc(50% - 2em);
  min-width: 360px;
  padding: 0.5em 0;
`

const links = css`
  color: #9d1517;
  text-decoration: none;
`

const sectionTop = css`
  margin-top: 2em;
`
const introduction = css`
  line-height: 1.6;
  padding: 2em 1em;
`

const IndexPage = () => (
  <Layout>
    <SEO title="首頁" />
    <h1>給您上山吃魚的美食饗宴</h1>
    <p>團體旅遊、家族聚會、社團餐敘</p>
    <section css={sectionTop}>
      <h3>聯絡資訊</h3>
      <ul>
        <li>
          電話： 05-2792222
          {` （`}
          <a href="tel:052792222" css={links}>
            歡迎來電洽詢
          </a>
          {`）`}
        </li>
        <li>傳真： 05-2793139</li>
        <li>
          營業時間：
          <ul>
            <li>中午 11:00 ~ 14:00</li>
            <li>晚上 17:00 ~ 21:00</li>
          </ul>
        </li>
        <li>
          地址： 嘉義縣竹崎鄉灣橋村李厝 66 號{` （`}
          <OutboundLink
            href={`https://www.google.com/maps/dir//嘉義縣竹崎鄉灣橋村李厝66號/`}
            css={links}
          >
            Google Map
          </OutboundLink>
          {`）`}
        </li>
      </ul>
    </section>
    <ImageView>
      <ImageWrapper>
        <Dumpling />
      </ImageWrapper>
      <ImageWrapper>
        <Sashimi />
      </ImageWrapper>
    </ImageView>
    <p css={introduction}>
      山魚珍美食館成立於 1998 年，1999
      年正式營運。最初構想是在山裡賣深海魚的概念，開始經營山魚珍美食館。
      店內陳設使用珍貴的台灣奇木，創造出獨特的用餐環境，為了讓客人能品嚐深海魚的美味，主打各式鮪魚料理，
      把整條鮪魚的美味充分發揮出來，吸引大量饕客聞香而來。此外，為了突顯嘉義山林獨特的美味，
      選用竹崎栽植天綠香、山蘇、生山葵來襯托出魚料理的新鮮口感，為的就是讓遠道而來的朋友而夠心滿意足。
    </p>
    <section css={sectionTop}>
      <Image />
    </section>
  </Layout>
)

export default IndexPage
