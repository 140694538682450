import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { css } from "@emotion/core"

const container = css`
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
`

const content = css`
  display: flex;
  width: 50%;
  justifycontent: center;
  flexwrap: wrap;
`

const images = css`
  flex-basis: 200px;
`

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fluid(maxWidth: 800, maxHeight: 800) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "rest001.jpg" }) {
        ...squareImage
      }
      image2: file(relativePath: { eq: "rest002.jpg" }) {
        ...squareImage
      }
      image3: file(relativePath: { eq: "rest003.jpg" }) {
        ...squareImage
      }
      image4: file(relativePath: { eq: "rest004.jpg" }) {
        ...squareImage
      }
    }
  `)
  console.log(data)
  return (
    <div css={container}>
      <div css={content}>
        <Img fluid={data.image1.childImageSharp.fluid} css={images} />
        <Img fluid={data.image2.childImageSharp.fluid} css={images} />
      </div>
      <div css={content}>
        <Img fluid={data.image3.childImageSharp.fluid} css={images} />
        <Img fluid={data.image4.childImageSharp.fluid} css={images} />
      </div>
    </div>
  )
}

// <Img fluid={data.image1.childImageSharp.fluid} />
export default Image
